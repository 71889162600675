import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import CTA from "../components/Cards/CTA";

export default function Studenklader({ data }) {
  const title = "Studentkläder - Så klär du dig rätt på studenten";
  return (
    <>
      <Page title={title}>
      <div className="py-3 bg-gray-50"><p className="flex max-w-screen-xl mx-auto items-center px-4 sm:px-8 justify-between w-full text-gray-700 text-sm md:text-base">Artikeln innehåller reklam genom annonslänkar.</p></div>
        <article className="container px-5 py-6 mx-auto info-content">
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            className="mb-5 rounded-xl"
            alt="studentkläder"
          />
          <h1 className="text-4xl text-gray-900 font-bold md:text-5xl">
            {title}
          </h1>
          <p>Att ta studenten är en viktig händelse i livet och det är en dag som man kommer att minnas för alltid. Något som däremot många fundera över gällande sin student är vad som man ska ha på sig.</p>
          <p>Det finns så många alternativ att välja mellan, vilket kan göra det svårt att veta vad som passar dig bäst.</p>
          <p>I den här artikeln vill vi ge dig en vägledning i processen att <strong>välja rätt studentkläder</strong>, samt besvara några av de vanligaste frågorna hos studenter.</p>
          <h2 id="vad-ska-man-ha-p-sig-p-studenten-">Vad ska man ha på sig på studenten?</h2>
          <p>När man tar studenten så är <a href="/studentmossa/">studentmössan</a> ett måste, men när det kommer till resten av outfiten så finns det större frihet.</p>
          <p>För killarna brukar en <strong>kostym med en vit skjorta</strong> vara ett säkert val. För att färdigställa looken kan man också välja mellan att bära en fluga eller en slips, beroende på vad som matchar din personliga stil.</p>
          <p>För tjejerna så är den <strong>vita studentklänningen</strong> den vanligaste outfiten. Det finns ett brett utbud av studentklänningar att välja mellan, från korta med spets till långa som lite mer formella.</p>
          <p>När du väljer studentklänning är det viktigt att ta hänsyn till din egen kroppstyp och personliga smak. Klackade skor och accessoarer som örhängen och armband är också vanliga för att färdigställa studentoutfiten.</p>
          <p>Nu när du har en liten bättre koll på de klassiska studentkläderna för tjejer och killar så kommer vi gå igenom de mer för sig i detalj.</p>
          <h2 id="kl-dsel-f-r-killar-p-studenten">Klädsel för killar på studenten</h2>
          <GatsbyImage
            image={data.kille.childImageSharp.gatsbyImageData}
            className="mb-5 rounded-xl"
            alt="studentkläder kille"
          />
          <p>När det kommer till klädsel för en kille vid studenten så är det vanligast att man går för en mer elegant och sofistikerad look.</p>
          <p>En <strong>mörk studentkostym</strong> tillsammans med en vit skjorta är en säker vinnare, och när det gäller färger så är mörkblå, svart och mörkgrå de vanligaste. Men du kan också välja en färg som matchar din personliga stil och smak.</p>
          <p>De flesta väljer också att bära <strong>svarta snörskor</strong> med sin studentkostym. Men ett par vita sneakers fungerar också väldigt bra om du föredrar det.</p>
          <p>Fluga eller slips är också väldigt populärt och här är det fritt fram att välja det alternativet som du känner dig mest bekväm med.</p>
          <h3 id="hur-mycket-kostar-en-studentkostym-">Hur mycket kostar en studentkostym?</h3>
          <p>Kostymer är rätt så dyra, men man kan också se det som en investering och något som man kommer kunna ha flera år framöver.</p>
          <p>I genomsnitt så spenderar en student omkring <strong>2000-3000 kronor</strong> på en ny studentkostym. Många studenter väljer också att köpa ett par svarta snörskor, vilket brukar kosta uppemot 1000 kronor.</p>
          <p>Detta är rätt så mycket pengar, och om du inte känner att du har råd för att lägga de pengarna på en ny kostym så kan du också hyra en för studenten. Det finns exempelvis en del kostymer på Hygglo som går att hyra.</p>
          <p>Om du inte känner för att ha kostym kan du också välja en annan outfit såsom ett par snygga jeans eller chinos tillsammans med en skjorta. En del väljer också att ha på sig en udda kavaj, vilket är en enklare klädkod.</p>
          <h3 id="ska-man-ha-slips-eller-fluga-p-studenten-">Ska man ha slips eller fluga på studenten?</h3>
          <p>Om du väljer en slips eller fluga är <strong>helt ut till dig</strong> och dina egna preferenser. Det finns också de som väljer att inte ha något alls.</p>
          <p>Generellt sätt så brukar slipsen användas vid mer formella tillställningar, som affärsmöten, bröllop och formella middagar. Medan flugan är mer avslappnad och används på fester och middagar med vänner.</p>
          <p>Om du därför vill gå efter en mer formell och traditionell look så kan slips därför vara ett bra val. Medan fluga är bättre om du vill ha en mer avslappnad och mindre formell look.</p>
          <p>Om du är osäker på vad du ska välja så kan du också fråga dina vänner och vad de funderar på att ha på sig. Det viktigaste att du känner dig bekväm och självsäker i ditt val.</p>
          <h2 id="kl-dsel-f-r-tjejer-p-studenten">Klädsel för tjejer på studenten</h2>
          <GatsbyImage
            image={data.tjej.childImageSharp.gatsbyImageData}
            className="mb-5 rounded-xl"
            alt="studentklänning tjej"
          />
          <p>När det gäller studentkläder för tjejer är det en hel värld av möjligheter att utforska. Den <strong>vita studentklänningen</strong> är den mest populära och för att att få ut det mesta av din studentklänning så ska du välja en som passar din kroppstyp och personliga stil.</p>
          <p>Studentklänningar finns i en mängd olika stilar. Från enkla och elegant till mer kraftfulla och detaljerade. De som är i spets eller har spetsdetaljer är en klassisk favorit, och de lägger till en extra touch till din look.</p>
          <p>Det finns många butiker där det går att hitta <a href="/studentklanningar/">snygga studentklänningar</a> såsom Nelly, Bubbleroom, Åhlens och Zalando. Det går också att köpa en begagnad studentklänning, vilket kan vara smart om man vill hålla nere kostnaderna.</p>
          <p>När det gäller <a href="/basta-damskor/">studentskor</a> så brukar tjejer bära klackade skor, men undvik att välja ett par som är alltför höga. Du vill inte ha ont i fötterna eller svårt att springa senare på <a href="/utspringet/">utspringet</a>.</p>
          <CTA header="På jakt efter studentklänning?" subHeader="Hitta din drömklänning hos Bubbleroom - stort utbud till bra priser" link="https://pin.bubbleroom.se/t/t?a=462891025&as=1773817588&t=2&tk=1&url=https://www.bubbleroom.se/sv/kl%C3%A4der/kvinna/studentkl%C3%A4nningar" />
          <h3 id="m-ste-man-v-lja-en-studentkl-nning-">Måste man välja en studentklänning?</h3>
          <p>Det finns ingen regel att man måste ha studentklänning under sin student. Det är helt upp till dig att välja det som du vill ha på dig.</p>
          <p>De som inte vill ha klänning brukar istället välja att ha på sig en snygg kjol eller byxor tillsammans med en blus eller linne. Du kan också lägga till en stilfull kavaj om du vill.</p>
          <p>Vad du vill ha på dig på din student är helt upp till dig, och bara för att alla andra väljer en typ av klädsel så behöver inte du välja det. Du bör alltid välja det som känns rätt för dig på din stora dag!</p>
          <h3 id="studentaccessoarer-f-r-att-full-nda-outfiten">Studentaccessoarer för att fullända outfiten</h3>
          <p>För att fullända sin studentoutfit så kan du också överväga att lägga till andra accessoarer. Det finns många små detaljer som kan göra en stor skillnad i din och höja din look.</p>
          <p>Förutom studentmössa som är ett måste, så kan smycken göra din outfit mer personlig. Halsband, armband, ringar och örhängen kan alla bidra till en harmonisk look och ge din outfit det lilla extra.</p>
          <p>Edblad har exempelvis många <a href="https://in.ahlens.se/t/t?a=1136856392&as=1773817588&t=2&tk=1&url=https://www.ahlens.se/sok?SearchTerm=edblad" rel="nofollow" target="_blank">fina smycken</a> som passar till studenten. På så sätt kan man skapa en mer personlig look och dyka upp med en unik stil vid din student.</p>
          <p>Tänk dock på att inte överdriva med smyckena, utan mindre kan ofta vara mer i det här fallet. Genom att bara lägga till ett halsband en ring så kan du förstärka din stil.</p>
          <h2 id="vad-ska-g-sterna-ha-p-sig-p-studenten-">Vad ska gästerna ha på sig på studenten?</h2>
          <p>Som gäst på en studentexamen, är det bäst att klä dig på ett sätt som passar evenemangets stil. Försök därför att välja en lite <strong>finare och festligare klädsel</strong>. Du behöver inte bära kostym som gäst.</p>
          <p>Som kvinna och gäst på studentmottagning så finns det också en oskriven regel att inte bära vitt. Detta beror på att den utexaminerade vanligtvis bär en vit klänning och därför ska man man ska inte “stjäla uppmärksamheten” från den som tar studenten.</p>
          <p>Det kan också vara en bra idé att undvika färger som är för ljusa eller flashiga så du inte vill stjäla strålkastarljuset från den nyutexaminerade studenten.</p>
          <p>Bortsett från detta så finns det inte så mycket mer att tänka på när det gäller klädsel för gäster. Det är upp till dig själv vilken stil du väljer, men en kjol eller ett par byxor med en fin blus eller skjorta är vanligtvis ett bra val.</p>
        </article>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "studenthattar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    kille: file(relativePath: { eq: "klader-kille.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tjej: file(relativePath: { eq: "studentklanning.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
